<template>
  <div>
    <div class="d-flex flex-row mb-0">
      <p class="text-2xl mb-6">Notifications</p>
    </div>
    <v-card class="container">
      <div class="d-flex flex-row mb-0">
        <v-text-field
          v-model="search"
          :prepend-icon="icons.mdiFileFind"
          label="Recherche"
          single-line
          hide-details
          class="v-search ml-2"
          dense
        ></v-text-field>
        <v-autocomplete
          outlined
          dense
          v-model="messageTypeIdSelected"
          :items="messageTypeList"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          label="Type"
          item-text="code"
          item-value="id"
          class="select-filter mx-4 shrink"
          @change="setSelectedMessageType"
        ></v-autocomplete>
        <v-autocomplete
          outlined
          dense
          v-model="departmentIdSelected"
          :items="departmentList"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          label="Department"
          item-text="name"
          item-value="id"
          class="select-filter ml-4 shrink"
          @change="setSelectedDepartment"
        ></v-autocomplete>
      </div>
      <v-card-text>
        <v-data-table
          locale="fr-FR"
          :page="options.page"
          :pageCount="numberOfPages"
          :headers="headers"
          :items="items"
          :expanded="expanded"
          :sort-by="'updated'"
          :sort-desc="true"
          :options.sync="options"
          :server-items-length="options.total"
          :loading="loading"
          show-expand
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:top>
            <!-- /\ -->
          </template>
          <template v-slot:item.identifier="{ item }">
            <v-chip :class="getColor(item.message_type_code)" dark class="mr-3">
              {{ item.message_type_code }}
            </v-chip>

            {{ truncate(item.identifier, 80) }}
          </template>
          <template v-slot:item.created="{ item }">
            <span>{{ truncate(item.created, 10, "") }}</span>
          </template>
          <template v-slot:item.updated="{ item }">
            <span>{{ truncate(item.updated, 10, "") }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row v-if="item.site_id">
                <v-col cols="1">site: </v-col>
                <v-col cols="11"
                  >ID:{{ item.site_id }}, name: {{ item.site_name }}</v-col
                >
              </v-row>
              <!-- <v-row v-if="item.category && item.category != ''">
                <v-col cols="1">category: </v-col>
                <v-col cols="11">{{ item.category }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="1">type: </v-col>
                <v-col cols="11">{{ item.message_type_code }}</v-col>
              </v-row> -->
              <v-row class="pt-3">
                <v-col cols="1">identifier</v-col>
                <v-col cols="11">{{ item.identifier }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="1">message</v-col>
                <v-col cols="11">
                  <pre>{{ item.message }}</pre>
                </v-col>
              </v-row>
              <v-row v-if="item.comment && item.comment != ''">
                <v-col cols="1">comment</v-col>
                <v-col cols="11">{{ item.comment }}</v-col>
              </v-row>
              <v-row v-if="item.log_identifier && item.log_identifier != ''">
                <v-col cols="1">log id</v-col>
                <v-col cols="11">{{ item.log_identifier }}</v-col>
              </v-row>
              <v-row v-if="item.module_path && item.module_path != ''">
                <v-col cols="1">module</v-col>
                <v-col cols="11">{{ item.module_path }}</v-col>
              </v-row>
              <v-row v-if="item.class_name && item.class_name != ''">
                <v-col cols="1">class</v-col>
                <v-col cols="11">{{ item.class_name }}</v-col>
              </v-row>
              <v-row v-if="item.function_name && item.function_name != ''">
                <v-col cols="1">function</v-col>
                <v-col cols="11">{{ item.function_name }}</v-col>
              </v-row>
              <v-row v-if="item.function_params && item.function_params != ''">
                <v-col cols="1">function params</v-col>
                <v-col cols="11">
                  <pre>{{ item.function_params }}</pre>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { saveRmraView } from "@/utils/rmr-analytics";
import axios from "@axios";
import {
  prettyNbs,
  round2Dec,
  truncate,
  ltruncate,
  formatTime,
} from "@/utils/formatting";
import { mdiPencil, mdiFileFind } from "@mdi/js";
export default {
  name: "Notifications",
  components: {},
  data() {
    return {
      departmentList: [],
      departmentIdSelected: null,
      messageTypeList: [
        { id: null, code: "Tous" },
        { id: 1, code: "premium" },
        { id: 2, code: "info" },
        { id: 3, code: "success" },
        { id: 4, code: "warning" },
        { id: 5, code: "danger" },
        { id: 6, code: "debug" },
        { id: 7, code: "error" },
        { id: 8, code: "fatal_error" },
      ],
      messageTypeIdSelected: null,
      messageTypeCodeSelected: null,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      numberOfPages: 0,
      items: [],
      expanded: [1],
      loading: true,
      tableFields: [
        // "id",
        // "user_id",
        // "department_id",
        // "site_id",
        "category",
        // "message_type_id",
        // "message_type_code",
        "identifier",
        // "message",
        "counter",
        // "comment",
        // "log_identifier",
        // "module_path",
        // "class_name",
        // "function_name",
        // "function_params",
        "created",
        "updated",
      ],
      dialog: {
        id: -1,
        isOpen: false,
      },
      headers: [],
      icons: {
        mdiPencil,
        mdiFileFind,
      },
    };
  },
  async created() {
    this.departmentList = await this.$store.dispatch(
      "common/getDepartmentList"
    );
    this.departmentList.unshift({
      id: null,
      name: "Tous",
      code: "all",
      shortname: "all",
    });

    if ("type" in this.$route.query) {
      this.messageTypeCodeSelected = this.$route.query.type;
      for (let item of this.messageTypeList) {
        if (item.code == this.messageTypeCodeSelected) {
          this.messageTypeIdSelected = item.id;
          break;
        }
      }
    } else {
      this.messageTypeIdSelected = this.messageTypeList[0]["id"];
    }

    if ("departmentShortname" in this.$route.params) {
      const department = this.departmentList.find(
        (dept) => dept.shortname === this.$route.params.departmentShortname
      );
      if (department) {
        this.selectedDepartmentId = department.id;
        this.selectedDepartmentShortname = department.shortname;
      } else {
        this.selectedDepartmentId = this.departmentList[0]["id"];
        this.selectedDepartmentShortname = this.departmentList[0]["shortname"];
      }
    } else {
      this.departmentIdSelected = this.departmentList[0]["id"];
      this.selectedDepartmentShortname = this.departmentList[0]["shortname"];
    }

    const headers = [];
    for (const [index, item] of this.tableFields.entries()) {
      headers.push({
        text: item,
        align: "start",
        value: item,
      });
    }
    this.headers = headers;
  },
  watch: {
    "options.itemsPerPage"() {
      this.askUpdateData();
    },
    "options.page"() {
      this.askUpdateData();
    },
    "options.sortBy"() {
      this.askUpdateData();
    },
    "options.sortDesc"() {
      this.askUpdateData();
    },
    search() {
      if (this.options.page == 0) return;
      this.options.page = 1;
      this.askUpdateData();
    },
    "this.$route.query": {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (typeof oldVal != val) {
          if ("type" in this.$route.query) {
            this.messageTypeCodeSelected = this.$route.query.type;
            for (let item of this.messageTypeList) {
              if (item.code == this.messageTypeCodeSelected) {
                this.messageTypeIdSelected = item.id;
                break;
              }
            }
          } else {
            this.messageTypeIdSelected = this.messageTypeList[0]["id"];
          }
          this.askUpdateData();
        }
      },
    },
  },
  methods: {
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    setSelectedDepartment(departmentId) {
      this.askUpdateData();
    },
    setSelectedMessageType(messageTypeId) {
      if (messageTypeId) {
        this.messageTypeCodeSelected = this.messageTypeList.filter(
          (item) => item.id == messageTypeId
        )[0].code;
      } else {
        this.messageTypeCodeSelected = "Tous";
      }
      this.$router.push({
        path: "notifications",
        query: { type: this.messageTypeCodeSelected },
      });
      this.askUpdateData();
    },
    askUpdateData() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.updateData();
      }, 500);
    },
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      const queryParams = {
        department_id: this.departmentIdSelected,
        page: page - 1,
        limit: itemsPerPage,
        ...(this.search ? { search: this.search } : {}),
        ...(this.messageTypeIdSelected
          ? { message_type_id: this.messageTypeIdSelected }
          : {}),
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };

      const { data } = await axios.get("/notifications/", {
        params: queryParams,
      });
      this.loading = false;
      this.items = data.items;
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
      this.expanded = [];
    },
    getColor(code) {
      const colors = {
        bonus: "yellow",
        info: "blue",
        success: "green",
        warning: "orange",
        danger: "red",
        debug: "black",
        error: "red",
        fatal_error: "red",
      };

      return colors[code];
    },
  },
  computed: {
    round2Dec() {
      return round2Dec;
    },
    prettyNbs() {
      return prettyNbs;
    },
    truncate() {
      return truncate;
    },
    ltruncate() {
      return ltruncate;
    },
    formatTime() {
      return formatTime;
    },
  },
  mounted() {
    this.askUpdateData();
    saveRmraView(this.$route);
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep .v-data-table__expanded.v-data-table__expanded__row,
.container::v-deep .v-data-table__expanded.v-data-table__expanded__content {
  background-color: rgb(236, 236, 236) !important;
  color: rgba(94, 86, 105, 0.87) !important;
  border: 20px solid rgba(94, 86, 105, 0.64) !important;
  font-size: 0.75rem;
  font-weight: 600;
}
.v-chip.v-chip--no-color.yellow {
  background-color: yellow;
}
.v-chip.v-chip--no-color.blue {
  background-color: blue;
}
.v-chip.v-chip--no-color.green {
  background-color: green;
}
.v-chip.v-chip--no-color.orange {
  background-color: orange;
}
.v-chip.v-chip--no-color.red {
  background-color: red;
}
.v-chip.v-chip--no-color.black {
  background-color: black;
}
</style>
